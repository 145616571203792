<template>
  <div class='convention-text'>
        <div class="title">注册及隐私条款</div>
        <div class='duanluo'>
            <h3>一、特别声明</h3>
            <p>尊敬的申请人，在正式申请MIT Technology Review Innovation Under 35前，请仔细阅读网站的用户注册与隐私协议。本《MIT Technology Review Innovation Under 35申请人注册与隐私协议》（以下简称“本协议”）签约方为MIT Technology Review Innovation Under 35申请人，(以下简称“申请人”）和北京演绎科技有限公司（以下简称“平台方”）。申请人使用本网站进行申请时将相关信息提交给平台方，即表明自愿成为本协议的授权人。平台方建议申请人完整地阅读并理解本协议，以帮助申请人了解并维护自己权益。如果申请人不同意本协议的任意内容，或者无法准确理解该条款的含义，请不要进行后续操作，可以联系平台方人员并获得协助。当申请人一经勾选“同意”本协议时，即表示同意并签署了本协议，同时也成为本协议的授权人，平台方成为被授权人。本协议即构成对各方有约束力的法律文件。</p>
            
        </div>
        <div class='duanluo'>
            <h3>二、申请人须知</h3>
            <p>1、申请人在使用平台方时须按操作提示填写相关信息（包括但不限于：本人姓名、手机号码、身份证号、单位名称、上传本人身份证正反面等），须确保所填写资料和信息的真实性、准确性，不得提供虚假信息或隐瞒重要事实。申请人的注册账号所有权及有关权益均归平台方所有及受平台方保护。平台方未经申请人允许不会向任何第三方泄露申请人的个人隐私，与此同时，平台方在申请人注册及申请各个环节中遇到的问题给与及时支持，保障申请人的个人权益。若因非平台方原因造成信息泄露，平台方不负任何法律责任。</p>
            <p>2、申请人在填写上述信息时，应遵守中国相关法律法规，不得将该平台服务用于任何非法目的，也不以任何非法方式使用该平台服务。申请人完成注册后对注册账号有使用权，账号仅供本人使用，不得私自转于他人。如果平台方发现并有合理理由认为该账号被他人利用，则有权在通知该用户的情况下对该账号进行暂时冻结，或终止提供向该账号的一切服务。并有权注销该账号，而无需向注册该账户的用户承担法律责任。</p>
            <p>3、申请人填写的推荐人信息、联系方式并确定发送推荐信链接后，将授权平台方获取推荐人信息及发送邮件至填写邮箱的权利。与此同时，申请人授权平台方查看推荐信内容，并在必要情况下，在与申请人沟通下，与推荐人进行进一步联系。平台方依法对上述所有内容进行保密，未经申请人允许，不会向第三方泄露。但若因非平台方原因及不可预测因素造成推荐人信息及推荐信内容泄露，平台方将不负任何法律责任。</p>
            <p>4、平台方将在合理的安全水平内使用各种安全保护措施以保障申请人的信息安全，遵循隐私保护条例，并且禁止任何不合理的，非法的获取、收集、利用申请者个人信息的行为。同时平台方建议申请者下载带有最新安全保护设备的浏览器，以便保护账户密码和信息的安全。在保护个人信息及授权内容的过程中，申请人如果遇到任何问题，可以及时与平台方进行联系，以便获得帮助。平台方会尽最大力量保障申请人的合法权益，但因为互联网的广泛获取性，平台方无法百分之百保障申请人的授权内容不会遭到非法分子利用网略空隙而造成的泄露，也无法保障因其他人为等任何原因造成的信息外流，申请人须知相应的风险。</p>
            
        </div>
        <div class='duanluo'>
            <h3>三、授权内容</h3>
            <p>1、申请人根据平台方要求提供、上传、发布或传输相关内容：包括但不限于申请人个人的姓名、出生日期、肖像、经常居住所地、联系电话、邮箱、工作单位名称、个人经历、主要成果等各种信息和资料，其承载形式包括但不限于文字、图形、图片、声音、音乐、视频、音视频、软件、程序、链接等形式（以下统称“授权内容”或“内容”）。</p>
            <p>2、申请人陈述并保证，对所有上传到平台方上的内容，其拥有完整权利（包括但不限于姓名权、肖像权、著作权、商标权等）或已取得所有必要授权，且不侵犯任何第三方的合法权益。同时，申请人有义务维持和立即更新前述授权内容，以保证这些授权内容的真实、准确、最新、有效和完整。此外，申请人同意授权平台方对上述内容享有不可撤销的查看权利，平台方将有权在法律范围内展示申请人中入选人员的上述信息进行宣传。</p>
            <p>3、授权期限：【自申请人使用本平台并将相关授权内容提交给平台方之日起，至申请人明确书面表示退出本平台之日止】。</p>
            <p>4、禁止的内容
                申请人理解并保证在本平台上提供、上传、发布或传输的内容不含有以下信息：<br />
                （1）反对宪法确定的基本原则的；<br />
                （2）危害国家统一、主权和领土完整的；<br />
                （3）泄露国家秘密、危害国家安全或者损害国家荣誉和利益的；<br />
                （4）煽动民族仇恨、民族歧视，破坏民族团结，或者侵害民族风俗、习惯的；<br />
                （5）宣扬邪教、迷信的；<br />
                （6）扰乱社会秩序，破坏社会稳定的；<br />
                （7）诱导未成年人违法犯罪和渲染暴力、色情、赌博、恐怖活动的；<br />
                （8）侮辱或者诽谤他人，侵害公民个人隐私等他人合法权益的；<br />
                （9）危害社会公德，损害民族优秀文化传统的；<br />
                （10）侵犯他人合法权益的；<br />
                （11）有关法律、行政法规和国家规定禁止的其他内容。<br />
                如因涉及以上内容并引起法律问题，申请人需自行解决，平台方不负任何法律责任。<br />
            </p>
        </div>
        <div class='duanluo'>
            <h3> 五、 法律适用及争议解决</h3>
            <p> 1、本协议的订立、生效、执行、终止、解释和争议解决均适用中华人民共和国的法律。</p>
            <p>2、凡因执行本协议或本协议有关的一切争议，均提请北京仲裁委员会仲裁，并适用其现行仲裁规则，北京仲裁委员会的裁决为终局裁决，对双方具有法律约束力。</p>
        </div>
        <div class='duanluo'>
            <p> 以上所有条款均在确保真实性前提下生效，申请人应确认所填写的信息真实、如果因申请者个人的隐瞒，疏漏导致任何纠纷与问题，平台方不负任何法律责任。同时对因申请人个人原因给平台方造成的任何损失，平台方依据法律保留并追溯相应权利及赔偿措施。</p>
        </div>
    </div>
</template>
<style lang="scss">
.convention-text{
    padding: 40px;
    width: 1200px;
    margin: 20px auto 70px;
    background: #fff;
    .title{
        text-align: center;
        font-size: 20px;
    }
    .duanluo{
        margin: 20px 0;
        p{
            line-height: 24px;
            text-align: justify;
            margin-bottom: 15px;
        }
    }
    }
</style>